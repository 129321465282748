/**
 * @deprecated Use {@link Feature} instead
 */
export interface Flag {
  uid: string;
  enable: boolean;
  description?: string;
  properties?: { [key: string]: string | number };
}

// New Feature API ST-19392

export enum Feature {
  Communications = "COMMUNICATIONS",
  Invoices = "INVOICES",
}
