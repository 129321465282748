import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { createStylesheet, darken, lighten, useTheme } from "../../utils/styles";
import { Feature } from "../../../feature/types";
import {
  AccountBox as AccountBoxIcon,
  ChevronRight as ChevronRightIcon,
  Dashboard as DashboardIcon,
  Description as DescriptionIcon,
  ExpandMore as ExpandMoreIcon,
  Inbox as InboxIcon,
  NetworkCheck as NetworkCheckIcon,
  Settings as SettingsIcon,
  Visibility as VisibilityIcon,
} from "../../components/icons";
import { permissionCodes, useHasPermission, useHasPermissions } from "../../../permission/hooks";
import { useLogo } from "../../../brand/hooks";
import { useHasAnyFeatureFlag, useHasFeatureEnabled, useHasFeatureFlag } from "../../../feature/hooks";
import { featureFlagsMap } from "../../../feature/utils";
import { useUserLevelMap } from "../../../user-level/hooks";
import MasqueradingChip from "./MasqueradingChip";
import { getStatementsAndInvoicesLabel } from "../../../billing/utils";
import { UICollapse, UIDivider, UIDrawer, UIHidden, UIList, UIListItem, UIListItemText } from "../../components";

interface MainProps {
  mobileOpen: boolean;
  handleDrawerToggle: () => void;
}

export const drawerWidth = 264;
const billPages = ["/bills", "/usage-analysis", "/service-analysis"];
const billDashPages = ["/bill-dashboard-monthly", "/bill-dashboard-trends"];
const statementPages = ["/statements", "/invoices"];
const accountDetailPages = ["/account"];
const assetsPages = ["/assets"];
const mobilePages = ["/mobile-management", "/bars"];
const activateMobilePages = ["/sim-activate", "/sim-disconnect"];
const paymentPages = ["/payment-type", "/card-payment"];
const manageContactsPages = ["/contacts", "/contact/new"];
const manageCostCentresPages = ["/manage-cost-centres", "/update-cost-centres"];
const billLimitAlertsPages = ["/bill-limit-alerts"];
const paymentDetailsPages = ["/payment-type", "/card-payment"];
const alertPages = ["/usage-alerts"];
const bundleAlertsPages = ["/bundle-alerts"];
const supportPages = ["/support", "/tickets", "/line-test", "/help", "/logic-monitor"];

function NavMenu({ mobileOpen, handleDrawerToggle }: MainProps) {
  const classes = useStyles({ drawerWidth });
  const location = useLocation();
  const theme = useTheme();
  const logo = useLogo();
  const navigate = useNavigate();
  const userLevels = useUserLevelMap();

  // Feature Flag Check
  const hasStatementsFeatureFlag = useHasFeatureFlag(featureFlagsMap.STATEMENTS);
  const hasCommunicationsFeature = useHasFeatureEnabled(Feature.Communications);
  const hasInvoicesFeatureFlag = useHasFeatureFlag(featureFlagsMap.INVOICES);
  const hasDirectDebitFeatureFlag = useHasFeatureFlag(featureFlagsMap.DIRECT_DEBIT);
  const hasCardFeatureFlag = useHasFeatureFlag(featureFlagsMap.CARD_PAYMENT);
  const hasAssetsFeatureFlag = useHasFeatureFlag(featureFlagsMap.ASSETS);
  const hasReportsGraphsFeatureFlag = useHasFeatureFlag(featureFlagsMap.REPORT_GRAPHS);
  const hasTicketsFeatureFlag = useHasFeatureFlag(featureFlagsMap.TICKETS);
  const hasLineTestFeatureFlag = useHasFeatureFlag(featureFlagsMap.LINE_TEST);
  const hasLogicMonitorFeatureFlag = useHasFeatureFlag(featureFlagsMap.LOGIC_MONITOR);
  const hasHelpFeatureFlag = useHasFeatureFlag(featureFlagsMap.HELP);
  const hasUsageAlertsFeatureFlag = useHasFeatureFlag(featureFlagsMap.USAGE_ALERTS);
  const hasBundleAlertsFeatureFlag = useHasFeatureFlag(featureFlagsMap.BUNDLE_ALERTS);
  const hasContactsFeatureFlag = useHasFeatureFlag(featureFlagsMap.CONTACTS);
  const hasCostCentreManagementFeatureFlag = useHasFeatureFlag(featureFlagsMap.COST_CENTRE_MANAGEMENT);
  const hasMobileManagementFeatureFlag = useHasAnyFeatureFlag([
    featureFlagsMap.AUTO_SWITCH,
    featureFlagsMap.BILL_LIMIT,
    featureFlagsMap.DATA_ROAMING,
    featureFlagsMap.MOBILE_BARS,
    featureFlagsMap.SIM_LOCK,
    featureFlagsMap.SIM_ORDER,
    featureFlagsMap.SIM_SWAP,
    featureFlagsMap.SIM_UNLOCK,
  ]);
  const hasSimActivateFeatureFlag = useHasFeatureFlag(featureFlagsMap.SIM_ACTIVATE);
  const hasBillLimitAlertsFeatureFlag = useHasFeatureFlag(featureFlagsMap.BILL_LIMIT_ALERTS);

  // Permissions Check
  const hasCommunicationsPermission = useHasPermission(permissionCodes.COMMUNICATIONS);
  const hasUserManagePermission = useHasPermission(permissionCodes.USERS_MANAGE);
  const hasBillingManagePermission = useHasPermission(permissionCodes.BILLING_MANAGE);
  const hasAccountManagePermission = useHasPermissions([permissionCodes.ACCOUNT_MANAGE, permissionCodes.ALERTS_ACCESS_FEATURE]);
  const hasContactsViewPermission = useHasPermissions([
    permissionCodes.ACCOUNT_VIEW_CONTACTS,
    permissionCodes.ALERTS_ACCESS_FEATURE,
  ]);
  const hasPaymentsPermission = useHasPermission(permissionCodes.ACCOUNT_PAYMENTS);
  const hasMobileManagementPermission = useHasPermission(permissionCodes.MOBILE_MANAGEMENT);
  const hasAssetsPermission = useHasPermission(permissionCodes.VIEW_ASSETS);
  const hasAlertsPermission = useHasPermission(permissionCodes.ALERTS_ACCESS_FEATURE);
  const hasCostCentreManagementPermission = useHasPermission(permissionCodes.COST_CENTRE_MANAGE);
  const hasSupportPermission = useHasPermission(permissionCodes.SUPPORT_ACCESS);
  const hasLogicMonitorPermission = useHasPermission(permissionCodes.SUPPORT_LOGIC_MONITOR);

  const hasStatementsPermission = Boolean(useHasPermission(permissionCodes.BILLING_STATEMENT));
  const hasInvoicesPermission = Boolean(useHasPermission(permissionCodes.BILLING_STATEMENT));

  const canView: Function = (type: string) => {
    switch (type) {
      case "home":
        return true;
      case "billing":
        return canView("bill-dashboard") || canView("bill-explorer") || canView("statements-invoices");
      case "bill-dashboard":
        return hasReportsGraphsFeatureFlag;
      case "bill-explorer":
        return hasBillingManagePermission;
      case "statements-invoices":
        return (hasStatementsFeatureFlag && hasStatementsPermission) || (hasInvoicesFeatureFlag && hasInvoicesPermission);
      case "asset-management":
        return (
          canView("search-assets") ||
          canView("mobile-management") ||
          canView("mobile-activation") ||
          canView("manage-cost-centres")
        );
      case "search-assets":
        return hasAssetsFeatureFlag && hasAssetsPermission;
      case "mobile-management":
        return hasMobileManagementFeatureFlag && hasMobileManagementPermission;
      case "mobile-activation":
        return hasSimActivateFeatureFlag && hasMobileManagementPermission;
      case "manage-cost-centres":
        return hasCostCentreManagementFeatureFlag && hasCostCentreManagementPermission;
      case "bill-limit-alerts":
        return hasBillLimitAlertsFeatureFlag && hasMobileManagementPermission;
      case "manage-account":
        return canView("contacts") || canView("account-details") || canView("payments");
      case "contacts":
        return hasContactsFeatureFlag && hasContactsViewPermission;
      case "account-details":
        return hasAccountManagePermission;
      case "payments":
        return hasPaymentsPermission && (hasDirectDebitFeatureFlag || hasCardFeatureFlag);
      case "alerts":
        return canView("usage-alerts") || canView("bundle-alerts") || canView("bill-limit-alerts");
      case "usage-alerts":
        return hasUsageAlertsFeatureFlag && hasAlertsPermission;
      case "bundle-alerts":
        return hasBundleAlertsFeatureFlag && hasAlertsPermission;
      case "support":
        return (
          canView("support-dashboard") ||
          canView("support-tickets") ||
          canView("line-test") ||
          canView("help") ||
          canView("logic-monitor")
        );
      case "support-dashboard":
        return false;
      case "support-tickets":
        return hasSupportPermission && hasTicketsFeatureFlag;
      case "line-test":
        return hasSupportPermission && hasLineTestFeatureFlag;
      case "help":
        return hasSupportPermission && hasHelpFeatureFlag;
      case "logic-monitor":
        return hasLogicMonitorFeatureFlag && hasLogicMonitorPermission;
      case "manage-users":
        return hasUserManagePermission;
      case "communications":
        return hasCommunicationsFeature && hasCommunicationsPermission;
      default:
        return false;
    }
  };

  const isPageActive = (pages: string[]): boolean => {
    return pages.some((p) => {
      if (p === "/usage") return location.pathname === p;
      else return location.pathname.startsWith(p);
    });
  };

  const [openBilling, setOpenBilling] = useState(isPageActive([...billPages, ...statementPages, ...billDashPages]));
  const [openAccount, setOpenAccount] = useState(
    isPageActive([...accountDetailPages, ...manageContactsPages, ...paymentDetailsPages]),
  );
  const [openAssets, setOpenAssets] = useState(
    isPageActive([...assetsPages, ...mobilePages, ...activateMobilePages, ...manageCostCentresPages]),
  );
  const [openSupport, setOpenSupport] = useState(isPageActive([...supportPages]));
  const [openAlerts, setOpenAlerts] = useState(isPageActive([...alertPages, ...bundleAlertsPages, ...billLimitAlertsPages]));

  const getClassNames = (pages: string[]) => `${classes.listItemNested} ${isPageActive(pages) ? classes.itemActive : ""}`;

  const drawer = (
    <div data-cy="nav-drawer" role="presentation" className={classes.listWrap}>
      <div className={classes.logo}>
        <div
          data-cy="nav-drawer-logo"
          style={{
            width: "200px",
            height: "110px",
            background: `url('${logo}') center / contain no-repeat`,
          }}
        />
      </div>
      <MasqueradingChip />
      <UIList disablePadding={false}>
        {canView("home") && (
          <UIListItem className={classes.listItem} data-cy="nav-drawer-item-home" onClick={() => navigate("/")}>
            <DashboardIcon className={classes.icon} />
            <UIListItemText primary="Home" classes={{ primary: classes.itemText }} />
          </UIListItem>
        )}
        {canView("billing") && (
          <>
            <UIDivider className={classes.divider} />
            <UIListItem
              className={classes.listItem}
              onClick={() => setOpenBilling(!openBilling)}
              data-cy="nav-drawer-item-billing"
            >
              <DescriptionIcon className={classes.icon} />
              <UIListItemText primary="Billing" classes={{ primary: classes.itemText }} />
              {openBilling ? <ExpandMoreIcon className={classes.moreIcon} /> : <ChevronRightIcon className={classes.moreIcon} />}
            </UIListItem>
            <UICollapse in={openBilling} timeout="auto" unmountOnExit>
              <UIList component="div" disablePadding>
                <UIDivider className={classes.divider} />
                {canView("bill-dashboard") && (
                  <UIListItem
                    className={getClassNames(billDashPages)}
                    onClick={() => navigate("/bill-dashboard-monthly")}
                    data-cy="nav-drawer-item-bill-dashboard"
                  >
                    <UIListItemText primary="Billing Dashboard" classes={{ primary: classes.itemTextNested }} />
                  </UIListItem>
                )}
                {canView("bill-explorer") && (
                  <UIListItem
                    className={getClassNames(billPages)}
                    onClick={() => navigate("/bills")}
                    data-cy="nav-drawer-item-bills"
                  >
                    <UIListItemText primary="Bill Explorer" classes={{ primary: classes.itemTextNested }} />
                  </UIListItem>
                )}
                {canView("statements-invoices") && (
                  <UIListItem
                    className={getClassNames(statementPages)}
                    onClick={() =>
                      navigate(
                        hasStatementsFeatureFlag && hasStatementsPermission
                          ? "/statements"
                          : hasInvoicesFeatureFlag && hasInvoicesPermission
                            ? "/invoices"
                            : "",
                      )
                    }
                    data-cy="nav-drawer-item-statements"
                  >
                    <UIListItemText
                      primary={getStatementsAndInvoicesLabel(
                        hasStatementsFeatureFlag && hasStatementsPermission,
                        hasInvoicesFeatureFlag && hasInvoicesPermission,
                      )}
                      classes={{ primary: classes.itemTextNested }}
                    />
                  </UIListItem>
                )}
              </UIList>
            </UICollapse>
          </>
        )}
        {canView("asset-management") && (
          <>
            <UIDivider className={classes.divider} />
            <UIListItem
              className={classes.listItem}
              onClick={() => setOpenAssets(!openAssets)}
              data-cy="nav-drawer-item-asset-management"
            >
              <NetworkCheckIcon className={classes.icon} />
              <UIListItemText primary={"Asset Management"} classes={{ primary: classes.itemText }} />
              {openAssets ? <ExpandMoreIcon className={classes.moreIcon} /> : <ChevronRightIcon className={classes.moreIcon} />}
            </UIListItem>
            <UICollapse in={openAssets} timeout="auto" unmountOnExit>
              <UIList component="div" disablePadding>
                <UIDivider className={classes.divider} />
                {canView("search-assets") && (
                  <UIListItem
                    className={getClassNames(assetsPages)}
                    onClick={() => navigate("/assets")}
                    data-cy="nav-drawer-item-assets"
                  >
                    <UIListItemText primary="Search Assets" classes={{ primary: classes.itemTextNested }} />
                  </UIListItem>
                )}
                {canView("mobile-management") && (
                  <UIListItem
                    className={getClassNames(mobilePages)}
                    onClick={() => navigate("/mobile-management")}
                    data-cy="nav-drawer-item-manage-mobiles"
                  >
                    <UIListItemText primary="Mobile Management" classes={{ primary: classes.itemTextNested }} />
                  </UIListItem>
                )}
                {canView("mobile-activation") && (
                  <UIListItem
                    className={getClassNames(activateMobilePages)}
                    onClick={() => navigate("/sim-activate")}
                    data-cy="nav-drawer-item-activate-disconnect-mobiles"
                  >
                    <UIListItemText primary="Activate/Disconnect Mobiles" classes={{ primary: classes.itemTextNested }} />
                  </UIListItem>
                )}
                {canView("manage-cost-centres") && (
                  <>
                    <UIListItem
                      className={getClassNames(manageCostCentresPages)}
                      onClick={() => navigate("/manage-cost-centres")}
                      data-cy="nav-drawer-item-manage-cost-centres"
                    >
                      <UIListItemText primary="Manage Cost Centres" classes={{ primary: classes.itemTextNested }} />
                    </UIListItem>
                  </>
                )}
              </UIList>
            </UICollapse>
          </>
        )}
        {canView("manage-account") && (
          <>
            <UIDivider className={classes.divider} />
            <UIListItem
              className={classes.listItem}
              onClick={() => setOpenAccount(!openAccount)}
              data-cy="nav-drawer-item-account"
            >
              <AccountBoxIcon className={classes.icon} />
              <UIListItemText primary={"Account Management"} classes={{ primary: classes.itemText }} />
              {openAccount ? <ExpandMoreIcon className={classes.moreIcon} /> : <ChevronRightIcon className={classes.moreIcon} />}
            </UIListItem>
            <UICollapse in={openAccount} timeout="auto" unmountOnExit>
              <UIList component="div" disablePadding>
                <UIDivider className={classes.divider} />
                {canView("contacts") && (
                  <UIListItem
                    className={getClassNames(manageContactsPages)}
                    onClick={() => navigate("/contacts")}
                    data-cy="nav-drawer-item-manage-contacts"
                  >
                    <UIListItemText primary="Manage Contacts" classes={{ primary: classes.itemTextNested }} />
                  </UIListItem>
                )}
                {canView("account-details") && (
                  <UIListItem
                    className={getClassNames(accountDetailPages)}
                    onClick={() => navigate("/account")}
                    data-cy="nav-drawer-item-account-details"
                  >
                    <UIListItemText primary={`${userLevels?.[30]?.name} Details`} classes={{ primary: classes.itemTextNested }} />
                  </UIListItem>
                )}
                {canView("payments") && (
                  <UIListItem
                    className={getClassNames(paymentPages)}
                    onClick={() =>
                      navigate(hasDirectDebitFeatureFlag ? "/payment-type" : hasCardFeatureFlag ? "/card-payment" : "")
                    }
                    data-cy="nav-drawer-item-account-payment-details"
                  >
                    <UIListItemText primary="Payment Details" classes={{ primary: classes.itemTextNested }} />
                  </UIListItem>
                )}
              </UIList>
            </UICollapse>
          </>
        )}
        {canView("alerts") && (
          <>
            <UIDivider className={classes.divider} />
            <UIListItem className={classes.listItem} onClick={() => setOpenAlerts(!openAlerts)} data-cy="nav-drawer-item-alerts">
              <VisibilityIcon className={classes.icon} />
              <UIListItemText primary={"Alerts"} classes={{ primary: classes.itemText }} />
              {openAlerts ? <ExpandMoreIcon className={classes.moreIcon} /> : <ChevronRightIcon className={classes.moreIcon} />}
            </UIListItem>
            <UICollapse in={openAlerts} timeout="auto" unmountOnExit>
              <UIList component="div" disablePadding>
                <UIDivider className={classes.divider} />
                {canView("usage-alerts") && (
                  <UIListItem
                    className={getClassNames([alertPages?.[0]])}
                    onClick={() => navigate("/usage-alerts")}
                    data-cy="nav-drawer-item-usage-alerts"
                  >
                    <UIListItemText primary="Usage Alerts" classes={{ primary: classes.itemTextNested }} />
                  </UIListItem>
                )}
                {canView("bundle-alerts") && (
                  <UIListItem
                    className={getClassNames([bundleAlertsPages?.[0]])}
                    onClick={() => navigate("/bundle-alerts")}
                    data-cy="nav-drawer-item-bundle-alerts"
                  >
                    <UIListItemText primary="Bundle Alerts" classes={{ primary: classes.itemTextNested }} />
                  </UIListItem>
                )}
                {canView("bill-limit-alerts") && (
                  <>
                    <UIListItem
                      className={getClassNames(billLimitAlertsPages)}
                      onClick={() => navigate("/bill-limit-alerts-cli")}
                      data-cy="nav-drawer-item-bill-limit-alerts"
                    >
                      <UIListItemText primary="Mobile Bill Limit Alerts" classes={{ primary: classes.itemTextNested }} />
                    </UIListItem>
                  </>
                )}
              </UIList>
            </UICollapse>
          </>
        )}
        {canView("support") && (
          <>
            <UIDivider className={classes.divider} />
            <UIListItem
              className={classes.listItem}
              onClick={() => setOpenSupport(!openSupport)}
              data-cy="nav-drawer-item-support"
            >
              <InboxIcon className={classes.icon} />
              <UIListItemText primary={"Support"} classes={{ primary: classes.itemText }} />
              {openSupport ? <ExpandMoreIcon className={classes.moreIcon} /> : <ChevronRightIcon className={classes.moreIcon} />}
            </UIListItem>
            <UICollapse in={openSupport} timeout="auto" unmountOnExit>
              <UIList component="div" disablePadding>
                <UIDivider className={classes.divider} />
                {canView("support-dashboard") && (
                  <UIListItem
                    className={getClassNames([supportPages?.[0]])}
                    onClick={() => navigate("/support")}
                    data-cy="nav-drawer-item-support-dashboard"
                  >
                    <UIListItemText primary="Dashboard" classes={{ primary: classes.itemTextNested }} />
                  </UIListItem>
                )}
                {canView("help") && (
                  <UIListItem
                    className={getClassNames([supportPages?.[3]])}
                    onClick={() => navigate("/help")}
                    data-cy="nav-drawer-item-help"
                  >
                    <UIListItemText primary={"Help"} classes={{ primary: classes.itemTextNested }} />
                  </UIListItem>
                )}
                {canView("support-tickets") && (
                  <UIListItem
                    className={getClassNames([supportPages?.[1]])}
                    onClick={() => navigate("/tickets")}
                    data-cy="nav-drawer-item-tickets"
                  >
                    <UIListItemText primary={"Tickets"} classes={{ primary: classes.itemTextNested }} />
                  </UIListItem>
                )}
                {canView("line-test") && (
                  <UIListItem
                    className={getClassNames([supportPages?.[2]])}
                    onClick={() => navigate("/line-test")}
                    data-cy="nav-drawer-item-line-test"
                  >
                    <UIListItemText primary={"Line Test"} classes={{ primary: classes.itemTextNested }} />
                  </UIListItem>
                )}
                {canView("logic-monitor") && (
                  <UIListItem
                    className={getClassNames([supportPages?.[4]])}
                    onClick={() => navigate("/logic-monitor")}
                    data-cy="nav-drawer-item-logic-monitor"
                  >
                    <UIListItemText primary={"Monitoring"} classes={{ primary: classes.itemTextNested }} />
                  </UIListItem>
                )}
              </UIList>
            </UICollapse>
          </>
        )}
        {canView("communications") && (
          <>
            <UIDivider className={classes.divider} />
            <UIListItem
              className={classes.listItem}
              onClick={() => navigate("/communications")}
              data-cy="nav-drawer-item-communications"
            >
              <DescriptionIcon className={classes.icon} />
              <UIListItemText primary="Communications" classes={{ primary: classes.itemText }} />
            </UIListItem>
          </>
        )}
        {canView("manage-users") && (
          <>
            <UIDivider className={classes.divider} />
            <UIListItem className={classes.listItem} data-cy="nav-drawer-item-users" onClick={() => navigate("/users")}>
              <SettingsIcon className={classes.icon} />
              <UIListItemText primary="Manage Users" classes={{ primary: classes.itemText }} />
            </UIListItem>
          </>
        )}
      </UIList>
    </div>
  );
  return (
    <nav className={classes.drawer}>
      <UIHidden smUp implementation="css">
        <UIDrawer
          variant="temporary"
          anchor={theme.direction === "rtl" ? "right" : "left"}
          open={mobileOpen}
          onClose={handleDrawerToggle}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true,
          }}
        >
          {drawer}
        </UIDrawer>
      </UIHidden>
      <UIHidden smDown implementation="css">
        <UIDrawer
          classes={{
            paper: classes.drawerPaper,
          }}
          variant="permanent"
          open
        >
          {drawer}
        </UIDrawer>
      </UIHidden>
    </nav>
  );
}

const useStyles = createStylesheet((theme, props: { drawerWidth: number }) => ({
  listWrap: {
    width: drawerWidth,
    backgroundColor: theme.palette.tertiary.main,
    height: "100%",
  },
  logo: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
  },
  listItem: {
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
    color: theme.palette.tertiary.contrastText,
    cursor: "pointer",
    "&:hover": {
      backgroundColor: darken(theme.palette.tertiary.main, 0.1),
    },
  },
  listItemNested: {
    padding: `${theme.spacing(1)} 30px`,
    backgroundColor: lighten(theme.palette.tertiary.main, 0.3),
    cursor: "pointer",
    "&:hover": {
      backgroundColor: lighten(theme.palette.tertiary.main, 0.2),
    },
  },
  itemText: {
    fontSize: "14px",
  },
  itemTextNested: {
    fontSize: "14px",
    color: theme.palette.tertiary.contrastText,
  },
  itemActive: {
    backgroundColor: lighten(theme.palette.tertiary.main, 0.15),
  },
  subItemActive: {
    backgroundColor: lighten(theme.palette.tertiary.main, 0.2),
  },
  icon: {
    marginRight: "20px",
    width: "20px",
    height: "20px",
  },
  moreIcon: {
    width: "18px",
    height: "18px",
  },
  divider: {
    backgroundColor: lighten(theme.palette.tertiary.main, 0.2),
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: props.drawerWidth,
      flexShrink: 0,
    },
    color: theme.palette.tertiary.contrastText,
  },
  drawerPaper: {
    width: props.drawerWidth,
    overflowX: "hidden",
    backgroundColor: theme.palette.tertiary.main,
    color: theme.palette.tertiary.contrastText,
  },
}));

export default NavMenu;
