import { useQuery, useQueryClient } from "react-query";
import { AjaxResponse } from "../_app/api";
import { getFeatureFlags, getEnabledFeatures } from "./api";
import { Feature, Flag } from "./types";
import { useStore } from "../_app/hooks";

/**
 * @deprecated Use {@link useEnabledFeatures} instead
 */
export function useFeatureFlags(options = {}) {
  const queryClient = useQueryClient();
  const { state } = useStore();
  const sessionUser = state.contextHierarchy?.lastParentId;

  return useQuery<Flag[], AjaxResponse>(["featureFlags"], () => getFeatureFlags(), {
    staleTime: 0,
    enabled: Boolean(sessionUser),
    onSuccess: () => {
      // Once the data is successfully fetched for the first time set a default stale time 15 minutes
      queryClient.setQueryDefaults("featureFlags", {
        staleTime: 1000 * 60 * 15,
      });
    },
    ...options,
  });
}

/**
 * @deprecated Use {@link useHasFeatureEnabled} instead
 */
export function useHasFeatureFlag(key: string | undefined) {
  const { data: features } = useFeatureFlags();

  return features?.some((feature) => feature?.uid === key && feature?.enable);
}

/**
 * @deprecated Use {@link useHasAnyFeatureEnabled} instead
 */
export function useHasAnyFeatureFlag(keys: string[] | string = []) {
  const { data: features } = useFeatureFlags();

  if (typeof keys === "string") {
    return features?.some((feature) => feature?.uid === keys && feature?.enable);
  }

  return keys?.some((key) => features?.some((feature) => feature?.uid === key && feature?.enable));
}

// New Feature API ST-19392

export function useEnabledFeatures(options = {}) {
  const { state } = useStore();
  const sessionUser = state.contextHierarchy?.lastParentId;

  return useQuery<Feature[], AjaxResponse>("enabledFeatures", () => getEnabledFeatures(), {
    staleTime: 1000 * 60 * 15,
    enabled: Boolean(sessionUser),
    ...options,
  });
}

export function useHasFeatureEnabled(feature: Feature): boolean {
  const { data: enabledFeatures } = useEnabledFeatures();
  return enabledFeatures?.includes(feature) ?? false;
}

export function useHasAnyFeatureEnabled(features: Feature[]): boolean {
  const { data: enabledFeatures } = useEnabledFeatures();

  if (!enabledFeatures || !features) return false;

  return features.some((feature) => enabledFeatures.includes(feature));
}
