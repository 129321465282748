import api from "../_app/api";
import { convertFiltersToQueries } from "../filter/api";
import {
  GetAutoSwitchResultsResp,
  GetDisconnectionAssetsResponse,
  GetDispatchedAssetsResponse,
  RequestAutoSwitchParams,
  SimDetails,
  SimSwapOperation,
} from "./types";

export const getSimDetails = async (simNumber: string, network: string) => {
  const { data }: { data: SimDetails } = await api({
    method: "GET",
    context_injection: true,

    url: `/assets/mobile/sim/${simNumber}`,
    params: { network },
  });
  return data;
};

export const requestAutoSwitch = async (params: RequestAutoSwitchParams) => {
  const parsedParams = convertFiltersToQueries(params);
  const { data }: { data: string } = await api({
    method: "POST",
    context_injection: true,

    url: "/assets/switch",
    data: parsedParams,
  });
  return data;
};

export const cancelAutoSwitch = async (assetId: any) => {
  const { data }: { data: any } = await api({
    method: "PUT",

    url: `/assets/switch/${assetId}/cancel`,
  });
  return data;
};

export const getAutoSwitchResults = async (requestId: string, page: number = 0, limit: number = 25) => {
  const { data }: { data: GetAutoSwitchResultsResp } = await api({
    method: "GET",
    context_injection: true,

    url: "/assets/switch",
    params: {
      hubRequestId: requestId,
      page,
      size: limit,
    },
  });
  return data;
};

export const getAllSimForActivation = async (page: number, limit: number) => {
  const { data }: { data: GetDispatchedAssetsResponse } = await api({
    method: "GET",
    context_injection: true,

    url: "/assets/mobile/dispatched",
    params: { page, size: limit },
  });

  return data;
};

export const getAllAssetsForAutoSwitchCancel = async (page: number, limit: number) => {
  const { data }: { data: GetDisconnectionAssetsResponse } = await api({
    method: "GET",
    context_injection: true,

    url: "/assets/mobile/disconnected",
    params: { page, size: limit },
  });

  return data;
};

export const activateSim = async (assetId: string, newSimNumber: string) => {
  const { data }: { data: any } = await api({
    method: "PUT",

    url: `/assets/${assetId}/sim/activate/`,
    params: {
      "new-sim-number": newSimNumber,
    },
  });
  return data;
};

export const performSimSwap = async (simData: SimSwapOperation[]) => {
  const { data }: { data: string } = await api({
    method: "PUT",
    context_injection: true,

    url: "/assets/sim/swap",
    data: simData,
  });
  return data;
};
