import { ReactNode, useEffect, useState } from "react";
import ToolbarMenu from "./components/ToolbarMenu";
import NavMenu, { drawerWidth } from "./components/NavMenu";
import Footer from "./components/Footer";
import { useHasPermissions } from "../../permission/hooks";
import LoadingOverlay from "../components/LoadingOverlay";
import { useStore } from "../hooks";
import { useBrandName } from "../../brand/hooks";
import AccountSelector from "../../context/components/AccountSelector";
import { useHasAnyFeatureFlag } from "../../feature/hooks";
import { useHasAccountLevelAccess, useIsHeadOrLoweUserLevel } from "../../user-level/hooks";
import { UIAlert, UIGrid, UILoader, UITypography, UITypographyProps } from "../components";
import { AccountLevel } from "../../account/utils";
import { createStylesheet, CssBaseline, CSSObject } from "../utils/styles";

interface MainProps {
  children: ReactNode;
  title?: string | ReactNode;
  subtitle?: string;
  titleProps?: UITypographyProps;
  contentProps?: any;
  isLoading?: boolean;
  accessPermission?: string | undefined | string[];
  featureFlag?: string | string[] | undefined;
  needSelectedAccount?: boolean;
  showNav?: boolean;
  showToolbar?: boolean;
  showFooter?: boolean;
  isModal?: boolean;
  accessGrantedCb?: Function;
  minimumAccountLevel?: AccountLevel;
}

function Main({
  title,
  titleProps,
  subtitle,
  contentProps = {},
  isLoading,
  accessPermission = "",
  featureFlag,
  needSelectedAccount = false,
  showNav = true,
  showToolbar = true,
  showFooter = true,
  isModal = false,
  accessGrantedCb,
  children,
  minimumAccountLevel,
  ...rest
}: MainProps) {
  const { state } = useStore();
  const brandName = useBrandName();
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = useState(false);
  const permissions =
    typeof accessPermission === "string" ? (accessPermission === "" ? [] : Array(accessPermission)) : accessPermission;
  const hasPermission = useHasPermissions(permissions, {
    refetchOnMount: true,
    staleTime: 60000,
  });
  const hasFeatureFlag = useHasAnyFeatureFlag(featureFlag);
  const hasFeature = featureFlag ? hasFeatureFlag : true;
  const hasAccountLevelAccess = useHasAccountLevelAccess(minimumAccountLevel);

  useEffect(() => {
    document.title = `${brandName} ${typeof title === "string" ? "- " + title : ""}`;
  }, [brandName, title]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const isOnlyGroupSelected = Boolean(state.contextHierarchy?.id === state.contextHierarchy?.lastParentId);

  const isHeadOrLowerSelected = useIsHeadOrLoweUserLevel(!isOnlyGroupSelected && state.contextHierarchy);

  const showTitle = needSelectedAccount ? Boolean(title) && !isOnlyGroupSelected : Boolean(title);

  const shouldShowToolbar = showToolbar && !state.isNestedApp;
  const shouldShowFooter = showFooter && !state.isNestedApp;
  const shouldShowNav = showNav && !state.isNestedApp;

  const renderContent = () => {
    if (hasPermission && hasFeature && needSelectedAccount && !isHeadOrLowerSelected) {
      return <AccountSelector />;
    }
    if (hasFeature && hasPermission && hasAccountLevelAccess) {
      setTimeout(() => {
        accessGrantedCb?.();
      });
      return children;
    }
    if (hasPermission === undefined) {
      return (
        <div className={classes.loader}>
          <UILoader />
        </div>
      );
    }

    return (
      <UIGrid className={classes.error}>
        <UIAlert severity="info">You do not have permission to view this page.</UIAlert>
      </UIGrid>
    );
  };

  if (!state.contextHierarchy?.lastParentId) return <LoadingOverlay />;

  return (
    <div className={classes.root} {...rest}>
      <CssBaseline />
      {shouldShowNav && <NavMenu mobileOpen={mobileOpen} handleDrawerToggle={handleDrawerToggle} />}
      <div className={`${classes.ctr} ${isModal && classes.modal}`} data-cy="main-ctr">
        {shouldShowToolbar && <ToolbarMenu handleDrawerToggle={handleDrawerToggle} />}
        <main {...contentProps} className={`${classes.main} ${contentProps.className}`}>
          {shouldShowToolbar && <div className={classes.toolbar} />}
          <UIGrid container spacing={1}>
            {showTitle && (
              <UIGrid size={{ xs: 12, md: 12 }}>
                <UITypography variant="h1" className={classes.title} data-cy={"page-title"} {...titleProps}>
                  {title}
                </UITypography>
                {subtitle && (
                  <UITypography variant="h3" color="textSecondary">
                    {subtitle}
                  </UITypography>
                )}
              </UIGrid>
            )}
            {isLoading ? (
              <div className={classes.loader}>
                <UILoader />
              </div>
            ) : (
              renderContent()
            )}
          </UIGrid>
        </main>
        {shouldShowFooter && <Footer />}
      </div>
    </div>
  );
}

const useStyles = createStylesheet((theme) => ({
  root: {
    display: "flex",
    justifyContent: "flex-end",
  },
  ctr: {
    backgroundColor: "#f0f4f8",
    flexGrow: 1,
    overflow: "hidden",
    maxWidth: `calc(100vw - ${drawerWidth}px)`,
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100vw",
    },
  },
  toolbar: theme.mixins.toolbar as CSSObject,
  loader: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "calc(100vh - 250px)",
    flex: 1,
  },
  main: {
    padding: theme.spacing(3),
    backgroundColor: "#ffffff",
    minHeight: "100vh",
  },
  title: {
    display: "flex",
    marginBottom: theme.spacing(1.5),
    justifyContent: "space-between",
  },
  error: {
    marginTop: theme.spacing(2),
  },
  modal: {
    "& main": {
      minHeight: 0,
    },
  },
}));

export default Main;
